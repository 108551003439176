import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userConfig: { logged: false, language: "es", literals: [] },
};

const userConfigSlice = createSlice({
  name: "aplicationConfig",
  initialState,
  reducers: {
    setUserLogged: (state, action) => {
      state.userConfig.logged = action.payload;
    },
    setUserLanguage: (state, action) => {
      state.userConfig.language = action.payload;
    },
    setLanguage: (state, action) => {
      state.userConfig.literals = action.payload;
    },
  },
});

export const { setUserLogged, setUserLanguage, setLanguage } = userConfigSlice.actions;
export default userConfigSlice.reducer;
