import React from "react";
import { useNavigate } from "react-router-dom";
//Components
import { Input } from "../../components/inputs/type1";
//Redux
import { useDispatch } from "react-redux";
import { setUserLogged } from "./../../reducers/userConfigSlice";
//Styles
import "./style.css";
//Api
import { login } from "../../api/antonioRuiz";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    const clave = document.getElementById("inputlogin").value;
    const data = {
      web: window.location.host,
      clave: clave,
    };
    const response = await login(data);
    if (response && response.length > 0) {
      console.log(response);
      dispatch(setUserLogged(true));
      navigate("/admin");
    }
  };

  return (
    <div className="login">
      <div className="loginTitle">Administración</div>
      <p>Clave</p>
      <Input id={"inputlogin"} type={"password"} placeholder="" disabled={""} onKeyPress={() => null} />
      <button className="loginButton" onClick={handleLogin}>
        Entrar
      </button>
    </div>
  );
}
