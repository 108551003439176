import React from "react";
import "./style.css";

export const Panel = ({ content, width, height, top, marginTop, bottom, left, right, transform, topShadow }) => {
  return (
    <>
      <div className="panel-background" style={{ marginTop: marginTop, top: top, left: left, transform: transform }}></div>
      <div
        className="panel"
        style={{ width: width, height: height, top: top, marginTop: marginTop, bottom: bottom, left: left, right: right, transform: transform }}
      >
        {content}
      </div>
    </>
  );
};
