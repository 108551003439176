//Redux
import { useSelector } from "react-redux";
//Components
import { Checkbox } from "../../components/checkbox/type1";
import { Input } from "../../components/inputs/type1";
import { Textarea } from "../../components/textarea/type1";
//Assets
import imgContact from "./../../assets/images/CABANA-CONTACTO.jpg";
//Styles
import "./style.css";
//
export function Contact() {
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  return (
    <>
      <div className="rowContent">
        <div className="pageTitle">{literals.menu.contact}</div>
      </div>

      <div className="rowContent">
        <div class="formContact">
          <Input id={"inputContactEmail"} placeholder={"E-mail*"} />
          <Input id={"inputContactName"} placeholder={literals.contact.name + "*"} />
          <Input id={"inputContactSurname"} placeholder={literals.contact.surname + "*"} />
          <Textarea id={"textareaMessage"} text={literals.contact.message} />
          <div className="contact-msg-0">{literals.contact.captchamsg}</div>
          <Checkbox text={literals.contact.registerNewsletter} clickEvent={() => null} />
          <Checkbox text={literals.contact.acceptConditions} clickEvent={() => null} />
          <div className="link contact-send" onClick={() => null}>
            {literals.contact.send}
          </div>
        </div>
      </div>

      <div className="rowContent">
        <div class="formContact">
          <p>
            <b>Caban Outdoor</b>
          </p>
          <p>cabanoutdoor@gmail.com</p>
          <p>+ 34 665 358 247</p>
          <p>14900 Lucena ( Córdoba)</p>
        </div>
      </div>

      <div className="rowContent">
        <div className="background-image" style={{ backgroundImage: `url(${imgContact})` }}>
          <div className="contact-msg-1">{literals.contact.text1}</div>
        </div>
      </div>
    </>
  );
}
