import { useEffect } from "react";
//Contents
import { Aviso, Politica } from "./contents";
//Styles
import "./style.css";

export function Information({ type }) {
  const Content = () => {
    switch (type) {
      case "Aviso":
        return <Aviso />;
      case "Politica":
        return <Politica />;

      default:
        break;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="rowContent information-page">
        <Content />
      </div>
    </>
  );
}
