import React, { useState } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import { Panel } from "./../../components/panel";
import { MdOutlineClose } from "react-icons/md";
//Assets
import IMG_1267 from "./../../assets/images/IMG_1267.jpg";
import _I7A0576 from "./../../assets/images/_I7A0576.JPG";
import _I7A0566 from "./../../assets/images/_I7A0566.JPG";
import _I7A0546 from "./../../assets/images/_I7A0546.JPG";
import _I7A0520 from "./../../assets/images/_I7A0520.JPG";
import _I7A0608 from "./../../assets/images/_I7A0608.JPG";
import _I7A0596 from "./../../assets/images/_I7A0596.JPG";
import _I7A0513 from "./../../assets/images/_I7A0513.JPG";
import _I7A0317 from "./../../assets/images/_I7A0317.JPG";
import _I7A0416 from "./../../assets/images/_I7A0416.JPG";
import _I7A0421 from "./../../assets/images/_I7A0421.JPG";
import _I7A0325 from "./../../assets/images/_I7A0325.JPG";
import _I7A0240 from "./../../assets/images/_I7A0240.JPG";
import IMG_9542 from "./../../assets/images/IMG_9542.jpg";
import IMG_7402 from "./../../assets/images/IMG_7402.jpg";
import IMG_WA0013 from "./../../assets/images/IMG-20240201-WA0013.jpg";
import IMG_WA0014 from "./../../assets/images/IMG-20240201-WA0014.jpg";
import IMG_WA0015 from "./../../assets/images/IMG-20240201-WA0015.jpg";
import IMG_WA0016 from "./../../assets/images/IMG-20240201-WA0016.jpg";
import IMG_WA0017 from "./../../assets/images/IMG-20240201-WA0017.jpg";
import IMG_WA0018 from "./../../assets/images/IMG-20240201-WA0018.jpg";
import IMG_WA0019 from "./../../assets/images/IMG-20240201-WA0019.jpg";
import IMG_WA0020 from "./../../assets/images/IMG-20240201-WA0020.jpg";
import IMG_WA0021 from "./../../assets/images/IMG-20240201-WA0021.jpg";
import IMG_WA0022 from "./../../assets/images/IMG-20240201-WA0022.jpg";
import IMG_WA0023 from "./../../assets/images/IMG-20240201-WA0023.jpg";
import IMG_WA0024 from "./../../assets/images/IMG-20240201-WA0024.jpg";
import IMG_WA0025 from "./../../assets/images/IMG-20240201-WA0025.jpg";
import IMG_WA0026 from "./../../assets/images/IMG-20240201-WA0026.jpg";

//Styles
import "./style.css";

export default function Jobs() {
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  const [modal, showModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const jobsList = [
    { id: 0, url: IMG_1267, title: "Gran Hotel Guadalpín Banús (Marbella)" },
    { id: 1, url: _I7A0576, title: "CocoBambú (Torremolinos)" },
    { id: 2, url: _I7A0566, title: "CocoBambú (Torremolinos)" },
    { id: 3, url: _I7A0546, title: "Bono Beach (Marbella)" },
    { id: 4, url: _I7A0520, title: "Bono Beach (Marbella)" },
    { id: 5, url: _I7A0608, title: "Melia Costa del Sol (Torremolinos)" },
    { id: 6, url: _I7A0596, title: "Melia Costa del Sol (Torremolinos)" },
    { id: 7, url: _I7A0513, title: "Bono Beach (Marbella" },
    { id: 8, url: _I7A0317, title: "Max Beach (Mijas Costa)" },
    { id: 9, url: _I7A0416, title: "Bono Beach (Marbella)" },
    { id: 10, url: _I7A0421, title: "Max Beach (Mijas Costa)" },
    { id: 11, url: _I7A0325, title: "Max Beach (Mijas Costa)" },
    { id: 12, url: _I7A0240, title: "Max Beach (Mijas Costa)" },
    { id: 13, url: IMG_9542, title: "The Beach club Higuerón (Benalmádena)" },
    { id: 14, url: IMG_7402, title: "Atenas Playa (Sancti Petri)" },
    { id: 15, url: IMG_WA0013, title: "Coconovo Beach (Sancti Petri)" },
    { id: 16, url: IMG_WA0014, title: "Coconovo Beach (Sancti Petri)" },
    { id: 17, url: IMG_WA0015, title: "Gran Hotel Guadalpín Banús (Marbella)" },
    { id: 18, url: IMG_WA0016, title: "Atenas Playa (Sancti Petri)" },
    { id: 19, url: IMG_WA0017, title: "Alberts Bar&Grill (Marbella)" },
    { id: 20, url: IMG_WA0018, title: "The Beach club Higuerón (Benalmádena)" },
    { id: 21, url: IMG_WA0019, title: "The Beach club Higuerón (Benalmádena)" },
    { id: 22, url: IMG_WA0020, title: "Levante Beach (Marbella)" },
    { id: 23, url: IMG_WA0021, title: "Alberts Bar&Grill (Marbella)" },
    { id: 24, url: IMG_WA0022, title: "Tiburón Playa (Sancti Petri)" },
    { id: 25, url: IMG_WA0023, title: "Tiburón Playa (Sancti Petri)" },
    { id: 26, url: IMG_WA0024, title: "El Chiringuito (Tarifa)" },
    { id: 27, url: IMG_WA0025, title: "Bahía Limón (Sotogrande)" },
    { id: 28, url: IMG_WA0026, title: "Atenas Playa (Sancti Petri)" },
  ];
  const handleImagePreview = (url = "") => {
    console.log("handleImagePreview");
    console.log(url);
    showModal(!modal);
    setPreviewImage(url);
  };

  return (
    <>
      <div className="rowContent">
        <div className="jobs-section-1">
          <div className="jobs-msg-1">{literals.jobs.text1}</div>
          <div className="flex wrap h-100">
            {jobsList.map((job, index) => {
              return (
                <div class="vividworknav">
                  <div className="blockImg" onClick={() => handleImagePreview(job.url)}>
                    <div key={"job_" + index} className="background-image jobs-img " style={{ backgroundImage: `url(${job.url})` }}></div>
                  </div>
                  <div class="work-text-content" onClick={() => handleImagePreview(job.url)}>
                    {job.title}
                  </div>
                </div>
              );
            })}
            {modal === true && (
              <Panel
                content={
                  <div key={"modal_"} className="background-image jobs-img " style={{ backgroundImage: `url(${previewImage})` }}>
                    <div className="closeModal">
                      <MdOutlineClose onClick={() => handleImagePreview("")} />
                    </div>
                  </div>
                }
                width={"95%"}
                height={"90vh"}
                top={"50%"}
                marginTop={window.scrollY + "px"}
                bottom={""}
                left={"50%"}
                right={""}
                transform={"translate(-50%, -50%)"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
