import React, { useState, useEffect } from "react";
//Api
import { getSubscriptions } from "../../api/antonioRuiz";
//Components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export const Subscribers = () => {
  const [subscribersList, setSubscribersList] = useState([]);
  const handleSubscribers = async () => {
    const data = {
      web: window.location.host,
    };
    const response = await getSubscriptions(data);
    if (response.length > 0) {
      setSubscribersList(response);
    }
  };

  useEffect(() => {
    handleSubscribers();
  }, []);

  return (
    <>
      <div className="rowContent adminRow">
        <div className="adminBlockTitle">Suscriptores</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell align="right">
                  <b>Fecha</b>
                </TableCell>
                <TableCell align="right">
                  <b>Estado</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscribersList.map((row) => (
                <TableRow key={row.email} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {row.email}
                  </TableCell>
                  <TableCell align="right">{row.date}</TableCell>
                  <TableCell align="right">{row.state === "1" ? "Activo" : "Baja"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
