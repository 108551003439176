import { useState } from "react";
//Redux
import { useSelector } from "react-redux";
//Components
import { Input } from "../../inputs/type1";
//Api
import { saveSubscription } from "./../../../api/antonioRuiz";
//Styles
import "./style.css";
import { Checkbox } from "../../checkbox/type1";

export const Newsletter = () => {
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const handlePrivacy = () => {
    setPrivacyChecked(!privacyChecked);
    if (privacyChecked) document.getElementById("inputNewsletter").setAttribute("disabled", "disabled");
    else document.getElementById("inputNewsletter").removeAttribute("disabled");
  };

  const handleEmail = (e) => {
    if (e.key === "Enter") {
      const data = {
        email: document.getElementById("inputNewsletter").value,
        web: window.location.host,
      };
      saveSubscription(data);
      document.getElementById("inputNewsletter").value = "";
    }
  };

  return (
    <div className="newsletter">
      <div className="flex">
        <div className="w50">
          <div className="newsletterTitle">{literals.newsletters.title}</div>
        </div>
        <div className="w50"></div>
      </div>
      <Input id={"inputNewsletter"} placeholder="E-mail*" disabled="disabled" onKeyPress={(e) => handleEmail(e)} />
      <Checkbox text={literals.newsletters.confirm} clickEvent={handlePrivacy} />
    </div>
  );
};
