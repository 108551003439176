export const Aviso = () => {
  return (
    <>
      <div className="information-block">
        <b>CONDICIONES DE USO DE LA WEB</b>
      </div>

      <div className="information-block">
        En esta página puede leer las condiciones bajo las que le proporciona contenidos y servicios desde este sitio web, y que usted acepta por el
        hecho de acceder a él. Son las Condiciones de Uso del sitio web.{" "}
      </div>

      <div className="information-block">
        <div>Identificación de nuestra empresa</div>
        <b>Luxury & Relax Ambient</b>
        Miguel Ángel Caballero Román C/Juan de Aguilar, 3. 14960, Rute (Córdoba)
      </div>

      <div className="information-block">
        <div>
          <b>Aceptación de estas Condiciones </b>
        </div>
        El hecho de que acceda a este sitio web implica que conoce, entiende y acepta las Condiciones de Uso en la versión existente en el momento del
        acceso. Dado que, en función de las normativas legales vigentes en cada momento, las Condiciones pueden sufrir modificaciones, le recomendamos
        que las consulte cada vez que acceda a este sitio web, así como nuestra Política de Privacidad.
      </div>

      <div className="information-block">
        <div>
          <b>Objeto del sitio. Exclusión de responsabilidades.</b>
        </div>
        A través de este sitio web, proporcionamos información sobre los servicios y/o productos que ofrecemos o comercializamos. Esa información se
        ofrece tal y como puede verla el usuario, no aceptando Luxury & Relax Ambient responsabilidad alguna por posibles perjuicios causados por: La
        inexistencia de la información o los servicios y/o productos ofrecidos, su inexactitud, su falta de actualización o la existencia de fallos en
        el acceso a parte o a la totalidad de los contenidos de este sitio web o de otros a los que se pueda acceder desde éste. La pérdida de
        información o daños en equipos causados por la utilización de este sitio web o de otros a los que se pueda acceder desde éste.
      </div>

      <div className="information-block">
        <div>
          <b>Utilización del sitio web </b>
        </div>
        Usted es consciente de que el uso de este sitio web tiene lugar bajo su única y exclusiva responsabilidad, y así lo acepta voluntaria y
        expresamente al acceder a él. Consiente, asimismo, en no utilizar ninguno de los contenidos incluidos en el sitio web para fines que sean
        ilícitos o vayan en contra del orden público, la moral y las buenas costumbres comúnmente aceptadas. En particular, y sin que ello suponga una
        limitación a lo aquí enunciado, usted se compromete también a no obtener, reproducir o distribuir los contenidos mostrados en este sitio web
        si no es para uso personal y en ningún caso lucrativo.
      </div>

      <div className="information-block">
        <div>
          <b>Derechos de propiedad intelectual </b>
        </div>
        Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en el sitio web son propiedad de Luxury & Relax
        Ambient o de terceros, sin que pueda entenderse que el acceso al sitio web le otorga a usted derecho alguno sobre las citadas marcas, nombres
        comerciales y/o signos distintivos. Asimismo, los contenidos son propiedad intelectual de nuestra empresa o de terceros, sin que puedan
        entenderse cedidos a usted ninguno de los derechos de explotación que existen o puedan existir sobre ellos.
      </div>

      <div className="information-block">
        <div>
          <b>Legislación aplicable </b>
        </div>
        Estas Condiciones de Uso se rigen por la legislación española (Ley Orgánica 15/1999, de 13 de diciembre, sobre protección de Datos de Carácter
        Personal -LOPD-)
      </div>
    </>
  );
};

export const Politica = () => {
  return (
    <>
      <div className="information-block f-bold">COOKIE POLICY</div>
      <div className="information-block f-bold"> Cookies</div>
      <div className="information-block">
        We use cookies to facilitate the use of our website. Cookies are small data files that allow us to compare and understand how our users
        navigate through our website, so that we can improve the navigation process accordingly. The cookies we use do not store any personal data or
        any information that can identify you. If you do not wish to receive cookies, please configure your Internet browser to erase them from your
        computer’s hard drive, block them or warn you if they are installed. To continue without changing your cookie settings, simply continue on the
        website.
      </div>
      <div className="information-block">You can find out more about cookies and their use at www.aboutcookies.org.</div>
      <div className="information-block">The types of cookies we use</div>
      <div className="information-block f-bold">Strictly necessary cookies:</div>
      <div className="information-block">
        These cookies are necessary for the correct use of the website, allowing access to sections that have security filters. Without these cookies,
        many of the services available would not be operational.
      </div>
      <div className="information-block f-bold">Navigation Cookies:</div>
      <div className="information-block">
        These cookies collect information about the use that visitors make of the website, for example pages viewed, loading errors…. It is generic
        and anonymous information, which does not include personal data, nor does it collect information that identifies visitors; the ultimate goal
        being to improve the functioning of the website.
      </div>
      <div className="information-block">By visiting our website, you accept the installation of these cookies on your device.</div>
      <div className="information-block f-bold">Functional Cookies:</div>
      <div className="information-block">
        These cookies allow us to remember information (such as your user name, language or the region you are in) and more personal characteristics.
        For example, the possibility of offering personalised content based on the information and criteria you have voluntarily provided. These
        cookies can also be used to remember changes made to text size, fonts and other customisable parts of the website. They are also used to
        provide some requested services, such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and
        your activity cannot be tracked on other websites.
      </div>
      <div className="information-block">By visiting our website, you agree to the installation of these cookies on your device.</div>
      <div className="information-block f-bold">How to manage cookies on computers</div>
      <div className="information-block">If you want to allow the use of cookies from our site, please follow the instructions below.</div>
      <div className="information-block f-bold">Google Chrome</div>
      <div className="information-block">
        When you open the browser, click on «tools» at the top and select the «options» tab. Under options, click «privacy». Check «allow cookie
        management».
      </div>
      <div className="information-block f-bold">Microsoft Internet Explorer 6.0, 7.0, 8.0, 9.0</div>
      <div className="information-block">
        When you open the browser, click on «tools» at the top and select the «options» tab. Check the «Privacy» tab to make sure it is set to a
        security level of medium or lower. If your Internet settings are not set to medium, cookies will be blocked.
      </div>
      <div className="information-block f-bold">Mozilla Firefox</div>
      <div className="information-block">
        When you open the browser, click on «tools» at the top and select the «options» tab. Select the Privacy icon Click on cookies, and tick:
        «allow the installation of cookies».
      </div>
      <div className="information-block f-bold">Safari</div>
      <div className="information-block">
        When you open the browser, click on «tools» at the top and select the «options» tab. Click on the «Security» tab and check whether the option
        «Block access by third party cookies» is ticked or not. Click on «Save».
      </div>
      <div className="information-block f-bold">Setting cookies on Mac</div>
      <div className="information-block">
        If you have a Mac and would like to allow access to our cookies on your computer, please follow the instructions below:
      </div>
      <div className="information-block">
        Microsoft Internet Explorer 5.0 on OSX 1. Go to «Explorer» and select «Preferences» in the navigation bar.
      </div>
      <div className="information-block">Scroll down until you see «Cookies» just below «Received files». Check «Do not ask again».</div>
      <div className="information-block f-bold">Safari on OSX</div>
      <div className="information-block">Go to Safari and select «Preferences» in the navigation bar.</div>
      <div className="information-block">Click on the «Security» tab and check the «accept cookies» option.</div>
      <div className="information-block">Select the option: «Only from the current site I am browsing».</div>
      <div className="information-block f-bold">Mozilla and Netscape on OSX</div>
      <div className="information-block">
        Go to «Mozilla» or «Netscape» and at the top of your browser, check the «Preferences» option. Scroll down until you see «Cookies» just below
        «Privacy and Security». Check the option «Allow access to cookies only from the current site».
      </div>
      <div className="information-block f-bold">Opera</div>
      <div className="information-block">
        Go to «Opera» and select «Menu» and «Settings» in the navigation bar. Select «Preferences» and click on the «Advanced» tab. Check the option
        «Accept cookies
      </div>
    </>
  );
};
