import { useNavigate } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Components
import { GoArrowUp } from "react-icons/go";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
//Components
import { Newsletter } from "../../../components/newsletters/type1";
//Styles
import "./style.css";

export const Footer = () => {
  const navigate = useNavigate();
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  const handleNewPage = (url) => {
    navigate(url);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    !logged && (
      <div className="footer">
        <div className="footer-newsletters">
          <Newsletter />
        </div>
        <div className="footer-links">
          <div className="flex">
            <div className="w50 home-block-footer-left">
              <p onClick={() => handleNewPage("/contact")}>{literals.menu.contact}</p>
              <p onClick={() => handleNewPage("/politica")}>{literals.menu.politicaPrivacidad}</p>
              <p onClick={() => handleNewPage("/aviso")}>{literals.menu.avisoLegal}</p>

              <p className="rssLink">
                <a href="https://www.facebook.com/luxuryrelaxambient">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/caban_outdoor/">
                  <FaInstagram size={18} style={{ marginBottom: "-2px" }} />
                </a>
              </p>
            </div>
            <div className="w50 home-block-footer-right">
              <p onClick={() => handleNewPage("/jobs")}>{literals.menu.jobs}</p>
              <p onClick={() => handleNewPage("/products")}>{literals.menu.products}</p>
              <p>
                <a href="/pdf/catalogo.pdf" download>
                  {literals.menu.catalog}
                </a>
              </p>
              <p>{literals.menu.company}</p>
            </div>
          </div>
        </div>
        <div className="arrowTop">
          <GoArrowUp onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })} />
        </div>
        <div className="copyright">Copyright © 2024 Caban SL. All rights reserved.</div>
      </div>
    )
  );
};
