import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
//Assets
import logo from "./../assets/logo/logo_1_blanco.png";
import videoPC from "./../assets/videos/olas.mp4";
import video from "./../assets/videos/olas_portada.gif";
import imgHomeJobs from "./../assets/images/_I7A0250.JPG";
import imgHomeMsg3 from "./../assets/images/_I7A9210.JPG";
import imgHomeMsg21 from "./../assets/images/_I7A9224.JPG";
import imgHomeMsg22 from "./../assets/images/_I7A9054.JPG";
import imgHomeMsg23 from "./../assets/images/_I7A9235.JPG";
import portadaCatalogo from "./../assets/images/PORTADA_CATALOGO.jpg";
//Assets Slider
import IMG_1267 from "./../assets/images/IMG_1267.jpg";
import _I7A0576 from "./../assets/images/_I7A0576.JPG";
import _I7A0566 from "./../assets/images/_I7A0566.JPG";
import _I7A0546 from "./../assets/images/_I7A0546.JPG";
import _I7A0520 from "./../assets/images/_I7A0520.JPG";
import _I7A0608 from "./../assets/images/_I7A0608.JPG";
import _I7A0596 from "./../assets/images/_I7A0596.JPG";
import _I7A0513 from "./../assets/images/_I7A0513.JPG";
import _I7A0317 from "./../assets/images/_I7A0317.JPG";
import _I7A0416 from "./../assets/images/_I7A0416.JPG";
import _I7A0421 from "./../assets/images/_I7A0421.JPG";
import _I7A0325 from "./../assets/images/_I7A0325.JPG";
import _I7A0240 from "./../assets/images/_I7A0240.JPG";
import IMG_9542 from "./../assets/images/IMG_9542.jpg";
import IMG_7402 from "./../assets/images/IMG_7402.jpg";
//Slideshow
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";

export default function Home() {
  const navigate = useNavigate();
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  useEffect(() => {
    window.innerWidth > 600 && document.getElementById("vid").play();
  }, []);

  const Slideshow = () => {
    const divStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "50vw",
    };
    const buttonStyle = {
      display: "none",
    };
    const properties = {
      prevArrow: (
        <button style={{ ...buttonStyle }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
            <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
          </svg>
        </button>
      ),
      nextArrow: (
        <button style={{ ...buttonStyle }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
            <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
          </svg>
        </button>
      ),
    };
    const slideImages = [
      { id: 0, url: IMG_1267, caption: "Gran Hotel Guadalpín Banús (Marbella)" },
      { id: 1, url: _I7A0576, caption: "CocoBambú (Torremolinos)" },
      { id: 2, url: _I7A0566, caption: "CocoBambú (Torremolinos)" },
      { id: 3, url: _I7A0546, caption: "Bono Beach (Marbella)" },
      { id: 4, url: _I7A0520, caption: "Bono Beach (Marbella)" },
      { id: 5, url: _I7A0608, caption: "Melia Costa del Sol (Torremolinos)" },
      { id: 6, url: _I7A0596, caption: "Melia Costa del Sol (Torremolinos)" },
      { id: 7, url: _I7A0513, caption: "Bono Beach (Marbella" },
      { id: 8, url: _I7A0317, caption: "Max Beach (Mijas Costa)" },
      { id: 9, url: _I7A0416, caption: "Bono Beach (Marbella)" },
      { id: 10, url: _I7A0421, caption: "Max Beach (Mijas Costa)" },
      { id: 11, url: _I7A0325, caption: "Max Beach (Mijas Costa)" },
      { id: 12, url: _I7A0240, caption: "Max Beach (Mijas Costa)" },
      { id: 13, url: IMG_9542, caption: "The Beach club Higuerón (Benalmádena)" },
      { id: 14, url: IMG_7402, caption: "Atenas Playa (Sancti Petri)" },
    ];
    return (
      <div className="slide-container">
        <Slide {...properties}>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}></div>
            </div>
          ))}
        </Slide>
        <div className="home-msg-2" onClick={() => navigate("/jobs")}>
          {literals.home.jobs}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="rowContent">
        {window.innerWidth > 600 ? (
          <video id="vid" autoplay muted loop width="100%">
            <source src={videoPC} type="video/mp4" />
          </video>
        ) : (
          <img src={video} alt="logo" width="100%" />
        )}
        <div className="home-img-video">
          <img src={logo} alt="logo" />
        </div>
        <div className="home-msg-video">{literals.home.text1}</div>
      </div>

      <div className="rowContent home-sectiontop-space">
        <div className="flex">
          <div className="w33 h-100 p-2">
            <div className="background-image home-img-col" style={{ backgroundImage: `url(${imgHomeMsg21})` }}></div>
            <div className="home-msg-3">{literals.home.text2}</div>
          </div>

          <div className="w33 h-100 p-2">
            <div className="background-image home-img-col" style={{ backgroundImage: `url(${imgHomeMsg22})` }}></div>
            <div className="home-msg-3">{literals.home.text3}</div>
          </div>

          <div className="w33 h-100 p-2">
            <div className="background-image home-img-col" style={{ backgroundImage: `url(${imgHomeMsg23})` }}></div>
            <div className="home-msg-3">{literals.home.text4}</div>
          </div>
        </div>
      </div>

      <div className="rowContent">
        <Slideshow />
      </div>

      <div className="rowContent">
        <div className="home-section-3">
          <div className="flex h-100">
            <div className="w50 background-image h-100" style={{ backgroundImage: `url(${imgHomeMsg3})` }}></div>
            <div className="w50 home-msg-3">{literals.home.text5}</div>
          </div>
        </div>
      </div>

      <div className="rowContent">
        <div className="flex">
          <div className="w50 container-home-catalogo">
            <img className="home-row-catalogo" src={portadaCatalogo} alt="portada-catalogo" />
          </div>
          <div className="w50 home-msg-4">
            <a href="/pdf/catalogo.pdf" download>
              {literals.home.downloadCatalog}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
