const url = "https://api.antonioruiz.net/v1/dashboard/action/";
const token = "tY7Uj58iJ7TY5Fh5U8";

const api = (data = null, path) => {
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getAnalytics = (data = null) => {
  let path = "getAnalytics";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveAnalytics = (data = null) => {
  let path = "saveAnalytics";
  api(data, path);
};

export const login = (data = null) => {
  let path = "login";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveSubscription = (data = null) => {
  let path = "saveSubscription";
  api(data, path);
};

export const getSubscriptions = (data = null) => {
  let path = "getSubscriptions";
  let headers = {
    method: "POST",
    body: JSON.stringify({ token: token, data: data }),
  };

  return fetch(url + path, headers)
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
    });
};
