import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ children, page }) => {
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);

  if (logged === undefined) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  if (page === "admin")
    if (!logged) {
      // user is not authenticated
      return <Navigate to="/login" />;
    }

  return children;
};
