import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//Sections
import Header from "./sections/header";
import Home from "./pages/home";
import About from "./pages/about";
import Jobs from "./pages/jobs";
import Products from "./pages/products";
import Admin from "./pages/admin";
import Login from "./pages/admin/login";
import { Subscribers } from "./pages/admin/subscribers";
import { Footer } from "./sections/footer/type1";
import { Contact } from "./pages/contact";
import { Information } from "./pages/information";
import { ProtectedRoute } from "./components/security/protectedRoute";
//Functions
import useLanguage from "./functions/useLanguage";

function App() {
  useLanguage();

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aviso" element={<Information type={"Aviso"} />} />
          <Route path="/politica" element={<Information type={"Politica"} />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute page="admin">
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/subscribers"
            element={
              <ProtectedRoute page="subscribers">
                <Subscribers />
              </ProtectedRoute>
            }
          />
          {/*<Route path="/search/:pathName" element={<Search />} />*/}
        </Routes>
        <div className="rowContent">
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
