//Redux
import { useSelector } from "react-redux";
//Assets
import imgAbout1 from "./../../assets/images/_I7A9235.JPG";
import imgAbout2 from "./../../assets/images/_I7A9260.JPG";
import imgAbout3 from "./../../assets/images/_I7A8877.JPG";
import imgAbout4 from "./../../assets/images/_I7A9224.JPG";
import portadaCatalogo from "./../../assets/images/PORTADA_CATALOGO.jpg";
//Styles
import "./style.css";

export default function About() {
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  return (
    <>
      <div className="rowContent">
        <div className="background-image" style={{ backgroundImage: `url(${imgAbout1})` }}>
          <div className="about-msg-1">{literals.about.text1}</div>
        </div>
      </div>

      <div className="rowContent">
        <div className="about-section-2">
          <div className="flex h-100">
            <div className="w50 background-image" style={{ backgroundImage: `url(${imgAbout2})` }}></div>
            <div className="w50 background-image" style={{ backgroundImage: `url(${imgAbout3})` }}></div>
          </div>
        </div>
      </div>

      <div className="rowContent">
        <div className="about-msg-3">{literals.about.text2}</div>
      </div>

      <div className="rowContent">
        <div className="about-msg-4">
          <div className="flex h-100">
            <div className="w50 background-image h-100" style={{ backgroundImage: `url(${imgAbout4})` }}></div>
            <div className="w50 about-msg-5">{literals.about.text3}</div>
          </div>
        </div>
      </div>

      <div className="rowContent">
        <div className="flex">
          <div className="w50 container-home-catalogo">
            <img className="home-row-catalogo" src={portadaCatalogo} alt="portada-catalogo" />
          </div>
          <div className="w50 home-msg-4">
            <a href="/pdf/catalogo.pdf" download>
              {literals.about.text4}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
