import React, { useState } from "react";
import { Panel } from "./../../components/panel";
import { MdOutlineClose } from "react-icons/md";
//Assets
import WA0002 from "./../../assets/images/IMG-20240130-WA0002.jpg";
import WA0003 from "./../../assets/images/IMG-20240130-WA0003.jpg";
import WA0004 from "./../../assets/images/IMG-20240130-WA0004.jpg";
import WA0005 from "./../../assets/images/IMG-20240130-WA0005.jpg";
import WA0006 from "./../../assets/images/IMG-20240130-WA0006.jpg";
import WA0007 from "./../../assets/images/IMG-20240130-WA0007.jpg";
import WA0008 from "./../../assets/images/IMG-20240130-WA0008.jpg";
import WA0009 from "./../../assets/images/IMG-20240130-WA0009.jpg";
import WA0010 from "./../../assets/images/IMG-20240130-WA0010.jpg";
import WA0011 from "./../../assets/images/IMG-20240130-WA0011.jpg";
import WA0012 from "./../../assets/images/IMG-20240130-WA0012.jpg";
import WA0013 from "./../../assets/images/IMG-20240130-WA0013.jpg";
import WA0014 from "./../../assets/images/IMG-20240130-WA0014.jpg";
import WA0015 from "./../../assets/images/IMG-20240130-WA0015.jpg";
import WA0016 from "./../../assets/images/IMG-20240130-WA0016.jpg";
import WA0017 from "./../../assets/images/IMG-20240130-WA0017.jpg";
//Styles
import "./style.css";

export default function Products() {
  const [modal, showModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const jobsList = [
    { id: 0, url: WA0002, title: "Tumbona Zahora" },
    { id: 1, url: WA0003, title: "Cama convertible Mallorca" },
    { id: 2, url: WA0004, title: "Detalle cojín" },
    { id: 3, url: WA0005, title: "Cama Japón" },
    { id: 4, url: WA0006, title: "Cama Japón" },
    { id: 5, url: WA0007, title: "Detalle cama balinesa" },
    { id: 6, url: WA0008, title: "Cama Sotogrande" },
    { id: 7, url: WA0009, title: "Tumbona California" },
    { id: 8, url: WA0010, title: "Tumbona California" },
    { id: 9, url: WA0011, title: "Cama Maison" },
    { id: 10, url: WA0012, title: "Tumbona Oasis" },
    { id: 11, url: WA0013, title: "Tumbona Oasis" },
    { id: 12, url: WA0014, title: "Cama sin dosel" },
    { id: 13, url: WA0015, title: "Tumbona Max" },
    { id: 14, url: WA0016, title: "Cama balinesa convertible" },
    { id: 15, url: WA0017, title: "Sofá Nara" },
  ];

  const handleImagePreview = (url = "") => {
    console.log("handleImagePreview");
    console.log(url);
    showModal(!modal);
    setPreviewImage(url);
  };

  return (
    <>
      <div className="rowContent">
        <div className="jobs-section-1">
          <div className="flex wrap h-100">
            {jobsList.map((job, index) => {
              return (
                <div class="vividworknav">
                  <div className="blockImg" onClick={() => handleImagePreview(job.url)}>
                    <div key={"job_" + index} className="background-image jobs-img " style={{ backgroundImage: `url(${job.url})` }}></div>
                  </div>
                  <div class="work-text-content" onClick={() => handleImagePreview(job.url)}>
                    <p>{job.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {modal === true && (
        <Panel
          content={
            <div key={"modal_"} className="background-image jobs-img " style={{ backgroundImage: `url(${previewImage})` }}>
              <div className="closeModal">
                <MdOutlineClose onClick={() => handleImagePreview("")} />
              </div>
            </div>
          }
          width={"95%"}
          height={"90vh"}
          top={"50%"}
          marginTop={window.scrollY + "px"}
          bottom={""}
          left={"50%"}
          right={""}
          transform={"translate(-50%, -50%)"}
        />
      )}
    </>
  );
}
