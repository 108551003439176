export const language = {
  menu: {
    home: "Home",
    galery: "Galery",
    jobs: "Jobs",
    products: "Products",
    about: "About us",
    catalog: "Catalog",
    contact: "Contact",
    politicaPrivacidad: "Privacy Policy",
    avisoLegal: "Legal warning",
    company: "Company",
  },
  home: {
    jobs: "PROJECTS",
    text1: "Furniture to desing experiences.",
    text2: "We manufacture all our products 100%, without imports and with high quality raw materials.",
    text3: "We adapt the designs to the needs of each client so that everything fits perfectly.",
    text4: "Committed to each project, offering short delivery times.",
    text5: "We manufacture all our products in a comprehensive and artisanal way, betting on sustainable and quality production.",
    downloadCatalog: "DOWNLOAD THE NEW CATALOG.",
  },
  newsletters: {
    title: "Subscribe to receive news",
    confirm: "I have read and accept the data protection policy.",
  },
  about: {
    text1:
      "CABAN was born from the experience of a great professional team, who after years of work and commitment manufacturing and designing outdoor and contract furniture solutions, have managed to work with great agents in the tourism sector, offering a quality, cared for and pampered product from the beginning.",
    text2:
      "We seek to be the most suitable professionals to rethink the new outdoor furniture needs that the current hospitality business environment demands of us, being a company experienced in continually working and reinventing itself to meet the needs of its clients.",
    text3: "We manufacture all our products in a comprehensive and artisanal way, betting on sustainable and quality production.",
    text4: "DOWNLOAD THE NEW CATALOG.",
  },
  contact: {
    name: "Name",
    surname: "Surname",
    message: "Message",
    captchamsg: "This site is protected by Google reCAPTCHA. Privacy Policy and Terms and Conditions.",
    registerNewsletter: "Sign up for Newsletter",
    acceptConditions: "I have read and accept the data protection policy.",
    send: "Send",
    text1: "WE DESIGN CUSTOM PROJECTS.",
  },
  jobs: {
    text1: "Personalization has led to the creation of tailored projects that reflect naturalness and elegance.",
  },
};
