export const language = {
  menu: {
    home: "Inicio",
    galery: "Galería",
    jobs: "Trabajos",
    products: "Productos",
    about: "Conócenos",
    catalog: "Catálogo",
    contact: "Contacto",
    politicaPrivacidad: "Política de privacidad",
    avisoLegal: "Aviso legal",
    company: "Empresa",
  },
  home: {
    jobs: "PROYECTOS",
    text1: "Furniture to desing experiences.",
    text2: "Fabricamos al 100% todos nuestros productos, sin importaciones y con materias primas de alta calidad.",
    text3: "Adaptamos los diseños a las necesidades de cada cliente para que todo encaje a la perfección.",
    text4: "Comprometidos con cada proyecto, ofreciendo cortos plazos de entrega.",
    text5: "Fabricamos de forma integral y artesanal todos nuestros productos, apostando por una producción sostenible y de calidad.",
    downloadCatalog: "DESCARGA EL NUEVO CATÁLOGO.",
  },
  newsletters: {
    title: "Suscríbete para recibir noticias",
    confirm: "He leído y acepto la política de protección de datos.",
  },
  about: {
    text1:
      "CABAN nace de la experiencia de un gran equipo profesional, que tras años de trabajo y compromiso fabricando y diseñando soluciones de mobiliario exterior y contract han conseguido trabajar con grandes agentes del sector turístico, ofreciendo un producto de calidad, cuidado y mimado desde el principio.",
    text2:
      "Buscamos ser los profesionales más idóneos para replantear las nuevas necesidades de mobiliario exterior que nos demanda el entorno actual empresarial hostelero, siendo una empresa experimentada en trabajar y reinventarse continuamente para atender las necesidades de sus clientes.",
    text3: "Fabricamos de forma integral y artesanal todos nuestros productos, apostando por una producción sostenible y de calidad.",
    text4: "DESCARGA EL NUEVO CATÁLOGO.",
  },
  contact: {
    name: "Nombre",
    surname: "Apellidos",
    message: "Mensaje",
    captchamsg: "Este sitio está protegido por reCAPTCHA de Google. Política de privacidad y Términos y condiciones.",
    registerNewsletter: "Regístrate para Newsletter",
    acceptConditions: "He leído y acepto la política de protección de datos.",
    send: "Enviar",
    text1: "DISEÑAMOS PROYECTOS A MEDIDA.",
  },
  jobs: {
    text1: "La personalización ha llevado a la creación de proyectos a medida que reflejan la naturalidad y la elegancia.",
  },
};
