//Actions
import { useSelector, useDispatch } from "react-redux";
import language from "./../constants/translations";
import { setLanguage } from "../reducers/userConfigSlice";

export default function useLanguage() {
  const dispatch = useDispatch();
  const activeLang = useSelector((state) => state.aplicationConfig.userConfig.language);

  switch (activeLang) {
    case "es":
      dispatch(setLanguage(language.es));
      break;
    case "en":
      dispatch(setLanguage(language.en));
      break;
    default:
      dispatch(setLanguage(language.en));
      break;
  }
}
