import React, { useState, useEffect } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Cell, PieChart, Pie, Legend } from "recharts";
//Api
import { getAnalytics } from "../../api/antonioRuiz";

export default function Admin() {
  const [dataVisits, setDataVisits] = useState([]);
  const [dataPlataform, setDataPlataform] = useState([]);
  const [dataSO, setDataSO] = useState([]);
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const handleAnalytics = async () => {
    const data = {
      web: window.location.host,
    };
    const response = await getAnalytics(data);
    if (response.length > 0) {
      console.log(response);

      //VISITS
      let preparedData = [];
      response.map((r) => {
        let formatedDate = r.visitDate.split(" ")[0];
        formatedDate = formatedDate.split("-")[2] + "-" + formatedDate.split("-")[1];
        let existDate = preparedData.filter((f) => f.name === formatedDate);
        if (existDate.length > 0) {
          existDate[0].uv = existDate[0].uv + 1;
        } else {
          preparedData.push({
            name: formatedDate,
            uv: 1,
          });
        }
      });
      setDataVisits(preparedData);

      //PLATAFORM
      let preparedDataPlataform = [
        { name: "Desktop", value: 0 },
        { name: "Mobile", value: 0 },
      ];
      response.map((r) => {
        if (r.plataform === "Desktop") preparedDataPlataform[0].value = preparedDataPlataform[0].value + 1;
        if (r.plataform === "Mobile") preparedDataPlataform[1].value = preparedDataPlataform[1].value + 1;
      });
      setDataPlataform(preparedDataPlataform);

      //SO
      let preparedSO = [
        { name: "Windows", value: 0 },
        { name: "Linux", value: 0 },
        { name: "Android", value: 0 },
        { name: "Otros", value: 0 },
      ];
      response.map((r) => {
        if (r.so === "Windows") preparedSO[0].value = preparedSO[0].value + 1;
        else if (r.so === "Linux") preparedSO[1].value = preparedSO[1].value + 1;
        else if (r.so === "Android") preparedSO[2].value = preparedSO[2].value + 1;
        else preparedSO[3].value = preparedSO[3].value + 1;
      });
      setDataSO(preparedSO);
    }
  };

  useEffect(() => {
    handleAnalytics();
  }, []);

  return (
    <>
      <div className="rowContent adminRow">
        <div className="adminBlockTitle">Visitas últimos 7 días</div>
        <LineChart width={window.outerWidth * 0.9} height={400} data={dataVisits}>
          <Line type="monotone" dataKey="uv" stroke="#000" />
          <CartesianGrid stroke="#f1f1f1" />
          <XAxis dataKey="name" stroke="#dadada" />
          <YAxis stroke="#dadada" />
        </LineChart>
      </div>
      <div className="rowContent adminRow flex">
        <div className="w-50">
          <div className="adminBlockTitle">Dispositivos últimos 7 días</div>
          <PieChart width={(window.outerWidth * 0.9) / 2} height={200}>
            <Legend />
            <Pie
              data={dataPlataform}
              cx={(window.outerWidth * 0.9) / 4}
              cy={100}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {dataPlataform.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className="w-50">
          <div className="adminBlockTitle">Plataformas últimos 7 días</div>
          <PieChart width={(window.outerWidth * 0.9) / 2} height={200}>
            <Legend />
            <Pie
              data={dataSO}
              cx={(window.outerWidth * 0.9) / 4}
              cy={100}
              startAngle={180}
              endAngle={0}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {dataSO.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </div>
      </div>
    </>
  );
}
