import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setUserLogged, setUserLanguage } from "./../../reducers/userConfigSlice";
//Constants
import { languages } from "../../constants/configuration";
//Assets
import logo from "./../../assets/logo/logo_1_gris.png";
import { GoX } from "react-icons/go";
import { MdLanguage } from "react-icons/md";
//Styles
import "./style.css";
//Api
import { saveAnalytics } from "../../api/antonioRuiz";

export const Header = () => {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.aplicationConfig.userConfig.logged);
  const language = useSelector((state) => state.aplicationConfig.userConfig.language);
  const literals = useSelector((state) => state.aplicationConfig.userConfig.literals);
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenuMobile, setShowMenuMobile] = useState(false);

  const links = !logged
    ? [
        { id: 1, name: literals.menu.home, url: "/" },
        {
          id: 2,
          name: literals.menu.galery,
          url: "dropdown",
          subcategory: [
            { id: 200, name: literals.menu.jobs, url: "/jobs" },
            { id: 201, name: literals.menu.products, url: "/products" },
          ],
        },
        { id: 3, name: literals.menu.about, url: "/about" },
        { id: 4, name: literals.menu.catalog, url: "download", href: "/pdf/catalogo.pdf" },
        { id: 5, name: literals.menu.contact, url: "/contact" },
        {
          id: 6,
          name: (
            <>
              <MdLanguage style={{ fontSize: "15px", marginBottom: "-3px" }} /> {languages.filter((f) => f.code === language)[0].name}
            </>
          ),
          url: "language",
          subcategory: languages
            .filter((f) => f.code !== language)
            .map((lang, index) => {
              return { id: 600 + index, name: lang.name, url: lang.code };
            }),
        },
      ]
    : [
        { id: 1, name: "Estadísticas", url: "/admin" },
        { id: 2, name: "Suscriptores", url: "/subscribers" },
        { id: 3, name: "Salir", url: "salir" },
      ];

  const saveDataVisitor = async () => {
    const data = {
      language: navigator.language,
      plataform: navigator.userAgentData.mobile ? "Mobile" : "Desktop",
      so: navigator.userAgentData.platform,
      web: window.location.host,
      url: window.location.href,
    };
    const response = await saveAnalytics(data);
    //if (response) console.log(response);
  };

  const handleCategories = (link, type) => {
    let stateCategory = document.getElementById("categories" + type + link.id);

    if (stateCategory.classList.contains("hide")) {
      stateCategory.classList.remove("hide");
      stateCategory.classList.add("show");
    } else {
      stateCategory.classList.remove("show");
      stateCategory.classList.add("hide");
    }
  };

  const menuType = (type, link) => {
    if (type === "desktop") {
      switch (link.url) {
        case "download":
          return (
            <a key={link.id + "d"} className="menuItem link" href="/pdf/catalogo.pdf" download>
              {link.name}
            </a>
          );
        case "dropdown":
          return (
            <div key={link.id + "d"} className="menuItem link" onClick={() => handleCategories(link, "D")}>
              {link.name}
              <div id={"categoriesD" + link.id} className="hide">
                {link.subcategory &&
                  link.subcategory.map((cat) => {
                    return (
                      <div key={link.id} className="menuItem link" onClick={() => navigate(cat.url)}>
                        {cat.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        case "language":
          return (
            <div key={link.id + "d"} className="menuItem link" onClick={() => handleCategories(link, "D")}>
              {link.name}
              <div id={"categoriesD" + link.id} className="hide">
                {link.subcategory &&
                  link.subcategory.map((cat) => {
                    return (
                      <div key={link.id} className="menuItem link" onClick={() => handleLanguage(cat.url)}>
                        {cat.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        default:
          return (
            <div key={link.id + "d"} className="menuItem link" onClick={() => handleMenuDesktop(link.url)}>
              {link.name}
            </div>
          );
      }
    } else {
      switch (link.url) {
        case "download":
          return (
            <a key={link.id + "m"} className="menuItem link" href="/pdf/catalogo.pdf" download>
              {link.name}
            </a>
          );
        case "dropdown":
          return (
            <div key={link.id + "m"} className="menuItem link" onClick={() => handleCategories(link, "M")}>
              {link.name}
              <div id={"categoriesM" + link.id} className="hide">
                {link.subcategory &&
                  link.subcategory.map((cat) => {
                    return (
                      <div key={link.id} className="menuItem link" onClick={() => handleMenuMobile(cat.url)}>
                        {cat.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        case "language":
          return (
            <div key={link.id + "m"} className="menuItem link" onClick={() => handleCategories(link, "M")}>
              {link.name}
              <div id={"categoriesM" + link.id} className="hide">
                {link.subcategory &&
                  link.subcategory.map((cat) => {
                    return (
                      <div key={link.id} className="menuItem link" onClick={() => handleLanguage(cat.url)}>
                        {cat.name}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        default:
          return (
            <div key={link.id + "m"} className="menuItem link" onClick={() => handleMenuMobile(link.url)}>
              {link.name}
            </div>
          );
      }
    }
  };

  const MenuDesktop = () => {
    return (
      <div className="menu menuDesktop">
        {links.map((link) => {
          return menuType("desktop", link);
        })}
      </div>
    );
  };

  const handleLanguage = (lang) => {
    dispatch(setUserLanguage(lang));
  };

  const handleMenuDesktop = (url) => {
    console.log(url.includes("changeLang"));
    if (url === "salir") {
      dispatch(setUserLogged(false));
      navigate("/");
    } else if (url !== null) navigate(url);
  };

  const handleMenuMobile = (url) => {
    setShowMenuMobile(!showMenuMobile);
    if (url === "salir") {
      dispatch(setUserLogged(false));
      navigate("/");
    } else if (url !== null) navigate(url);
  };

  const MenuMobile = () => {
    return (
      <div className="menu menuMobile">
        <div onClick={() => handleMenuMobile(null)} style={{ paddingTop: "25px" }}>
          <div class="line" style={{ width: "60px" }}></div>
          <div class="line" style={{ width: "40px", marginLeft: "20px" }}></div>
          <div class="line" style={{ width: "20px", marginLeft: "40px" }}></div>
        </div>

        {showMenuMobile === true && (
          <div className="menuMobileContainer">
            <div className="menuItem link" onClick={() => handleMenuMobile(null)}>
              <div className="closeMobileContainer">
                <GoX />
              </div>
            </div>
            {links.map((link) => {
              return menuType("mobile", link);
            })}
            <img className="logoMobileContainer" src={logo} alt="logo" />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    saveDataVisitor();
  }, []);

  return (
    <div className="rowContent header">
      <div className="logo">
        <a href="/">
          {console.log(location.pathname)}
          {location.pathname !== "/" ? <img src={logo} alt="logo" /> : <></>}
        </a>
      </div>
      <MenuDesktop />
      <MenuMobile />
    </div>
  );
};

export default Header;
